import React, { useState } from "react"
import { FeedbackPageContainer } from "./style"
import { Form } from "../Form"
import { TextInput } from "../Form/TextInput"
import { RadioInput } from "../Form/RadioInput"
import { SelectInput } from "../SelectInput"
import { SubmitButton } from "components/globalStyles"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { useStaticQuery, graphql } from "gatsby"

const CREATE_FEEDBACK = gql`
  mutation CreateFeedback(
    $message: String!
    $userType: String!
    $useful: String!
    $easy: String!
    $foundOut: String!
    $fullName: String!
    $email: String!
    $phone: String!
  ) {
    createFeedback(
      input: {
        clientMutationId: "Generic Feedback"
        feedbackType: "generic"
        page: "Generic"
        initialQuestion: "Generic Feedback"
        initialResponse: "yes"
        message: $message
        userType: $userType
        useful: $useful
        easy: $easy
        foundOut: $foundOut
        fullName: $fullName
        email: $email
        phone: $phone
      }
    ) {
      data
      success
    }
  }
`

const FeedbackPage = () => {
  const [submitted, setSubmitted] = useState(false)
  const [createFeedback] = useMutation(CREATE_FEEDBACK)

  const onSubmit = values => {
    setSubmitted(true)
    console.log("submitted:", values)

    window.location.href = "#thanks"

    createFeedback({
      variables: {
        userType: values.userType,
        useful: values.useful,
        easy: values.easy,
        message: values.message.length ? values.message : "n/a",
        foundOut: values.foundOut.value?.length ? values.foundOut.value : "n/a",
        fullName: values.fullName.length ? values.fullName : "n/a",
        email: values.email.length ? values.email : "n/a",
        phone: values.phone.length ? values.phone : "n/a",
      },
    })
  }

  const options = [
    { value: "Contact with SSRV", label: "Contact with SSRV" },
    { value: "Internet/Another Website", label: "Internet/Another Website" },
    {
      value: "Referred by an Organisation/Support Worker",
      label: "Referred by an Organisation/Support Worker",
    },
    { value: "Referred by Family/Friend", label: "Referred by Family/Friend" },
    { value: "Promotional Materials", label: "Promotional Materials" },
    { value: "Community Legal Education", label: "Community Legal Education" },
    { value: "Other", label: "Other" },
  ]

  const feedbackData = useStaticQuery(
    graphql`
      {
        wpgraphql {
          siteOptions {
            siteSettings {
              dedicatedFeedbackSubmittedText
            }
          }
        }
      }
    `
  )

  const submittedText =
    feedbackData?.wpgraphql?.siteOptions?.siteSettings
      ?.dedicatedFeedbackSubmittedText

  return (
    <FeedbackPageContainer id="thanks">
      <h3>{submitted ? "Thanks for your feedback" : "Feedback form"}</h3>
      {submitted && submittedText && (
        <p
          className="submittedText"
          dangerouslySetInnerHTML={{
            __html: submittedText,
          }}
        />
      )}

      {!submitted && (
        <Form onSubmit={onSubmit}>
          <p className="label">Which describes you best?</p>
          <RadioInput name="userType" value="Applicant" />
          <RadioInput name="userType" value="Support worker" />
          <RadioInput name="userType" value="Health professional" />
          <RadioInput name="userType" value="Other" initialValue="Applicant" />

          <p className="label">Was this website useful?</p>
          <RadioInput name="useful" value="yes" label="Yes" />
          <RadioInput name="useful" value="no" label="No" initialValue="yes" />

          <p className="label">Was the website easy to use?</p>
          <RadioInput name="easy" value="yes" label="Yes" />
          <RadioInput name="easy" value="no" label="No" initialValue="yes" />

          <p className="label">How did you find out about this website?</p>
          <SelectInput
            options={options}
            name="foundOut"
            type="select"
            placeholder="Select one"
          />

          <p className="label">
            How has the DSP Help website helped you to understand the DSP? What
            could be improved?
          </p>
          <TextInput name="message" type="textarea" />
          <h4>Your feedback helps improve and update the DSP Help website</h4>
          <p className="label">Can we contact you in 1-3 months’ time to discuss how you used the information and resources from the DSP Help website? If yes, please provide your contact details below.</p>

          <p className="label">Full name (optional)</p>
          <TextInput name="fullName" type="text" />

          <p className="label">Email address (optional)</p>
          <TextInput name="email" type="email" />
          <p className="label">Phone number (optional)</p>
          <TextInput name="phone" type="text" short />

          <SubmitButton type="submit" label="Submit">
            Send feedback
          </SubmitButton>
        </Form>
      )}
    </FeedbackPageContainer>
  )
}

export default FeedbackPage
