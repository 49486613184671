import styled from "styled-components"
import { device } from "breakpoints"

export const FeedbackPageContainer = styled.div`
  padding-top: 55px;
  padding-bottom: 15px;
  @media ${device.desktop} {
    padding-top: 70px;
    padding-bottom: 45px;
  }
  h3 {
    font-size: 24px;

    @media ${device.desktop} {
      font-size: 30px;
    }
  }

  h4 {
    font-size: 20px;
    margin-bottom: -18px;
  }

  button {
    @media ${device.desktop} {
      margin-top: 70px;
    }
  }

  > p.submittedText {
    font-size: 18px;
    margin-top: 15px;

    @media ${device.desktop} {
      font-size: 20px;
      margin-top: 18px;
    }

    a {
      display: inline;
      position: relative;
      color: ${props => props.theme.linkColor};
      background-image: linear-gradient(to right, black, black) !important;
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      transition: 80ms;
      :hover {
        color: #796148;
        background-image: linear-gradient(to right, #796148, #796148) !important;
      }
    }
  }
`
