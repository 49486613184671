import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"

export const SingleValueContainer = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
  span {
    font-weight: 400;
    font-size: 18px;
    color: #71716f;
    white-space: nowrap;
    position: absolute;
    display: block;
    padding-right: 10px;
    top: 0;
    width: 100%;
    left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
 
  }
`
export const SelectContainer = styled.div`
  width: 100%;

  @media ${device.desktop} {
    width: 402px;
  }
  .css-1hwfws3 {
    padding: 0;
  }

  .basic__control {
    box-shadow: 0;
    border: 1px solid black;
    background: none;
    outline: none !important;
    border-radius: 0;
    height: 46px;
    min-height: auto;
    outline: none;
    cursor: pointer;
    padding: 0 15px;

    &--is-focused {
      box-shadow: none;
      border: 1px solid #c7b8a8;

      ${SingleValueContainer} {
        span {
          color: black;
        }
      }
    }

    :hover {
      border: 1px solid #c7b8a8;
    }
  }

  .basic__value-container {
    /* height: 100%; */
    padding: 0;
    border-radius: 0;
  }
  .basic__indicator-separator {
    display: none;
  }
  .basic__menu {
    margin-top: 0;
    border: 1px solid #c7b8a8;
    border-top: none;
    background-color: #f6f6f4;
    border-radius: 0;
  }
  .basic__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .basic__option {
    font-weight: 400;
    font-size: 18px;
    color: black;
    padding: 10px 12px 9px 15px;
    border-radius: 0;
    display: flex;
    align-items: center;

    :hover {
      background: #e5ded6;
    }
    :active {
      background: #c7b8a8;
    }
  }
  .basic__option--is-focused {
    transition: 90ms;
    cursor: pointer;
    background: transparent;
  }

  .basic__option--is-selected {
    background: #c7b8a8 !important;
  }
`

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
    transform: rotateX(${props => (props.open ? "180deg" : "0")});
    transition: ${props => (props.open ? "300ms" : "300ms")};
    path {
      stroke: black;
    }
  }
`

export const Placeholder = styled(SingleValueContainer)`
  background: red;
`
