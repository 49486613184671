import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { InternalPageWrapper } from "components/globalStyles"
import { InternalHeader } from "../components/InternalHeader"
import { Content } from "components/Content"
import CTAModule from "../components/CTAModule/CTAModule"
import { FeedbackPage } from "../components/FeedbackPage"

const Feedback = ({ pageContext }) => {
  const { title, pageSettings } = pageContext

  return (
    <Layout pageContext={pageContext}>
      <SEO title={title} />
      <InternalPageWrapper>
        <InternalHeader
          title={title}
          headerDescription={pageSettings.pageHeaderDescription}
          color="neutral"
        />
        <FeedbackPage />
      </InternalPageWrapper>
      {pageSettings?.ctaBlock?.title && (
        <CTAModule pageColor="neutral" data={pageSettings.ctaBlock} />
      )}
    </Layout>
  )
}

export default Feedback
